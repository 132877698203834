var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"vista-formulario"},[_c('div',{staticClass:"tarjeta-form animated fadeInUp"},[_c('h1',{staticClass:"h5 user-select-none"},[_vm._v("Global Trackit System")]),_c('h3',{staticClass:"my-3 user-select-none"},[_vm._v(" "+_vm._s(_vm.esRecuperarClave ? 'Nueva clave' : 'Recuperar cuenta')+" ")]),_c('form',{staticClass:"row justify-content-center",on:{"submit":function($event){$event.preventDefault();_vm.esRecuperarClave ? _vm.validarClave() : _vm.submitForm()}}},[(_vm.esRecuperarClave)?_c('div',{staticClass:"row mx-0"},[_c('div',{class:['col-12',
            {'campo': _vm.validarValores.clave
              && _vm.validarValores.clave.length},
            {'valido': _vm.validarValores.clave == 'valido'},
            {'invalido': _vm.validarValores.clave == 'invalido'} ]},[_c('mdb-input',{staticClass:"mt-2 mb-3",attrs:{"type":_vm.mostrarCamposClave.clave ? 'text' : 'password',"minlength":"8","label":"Clave","outline":""},model:{value:(_vm.clave),callback:function ($$v) {_vm.clave=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"clave"}},[_c('mdb-btn',{attrs:{"slot":"append","group":"","flat":"","dark-waves":"","size":"md","icon":_vm.mostrarCamposClave.clave ? 'eye-slash' : 'eye',"title":_vm.mostrarCamposClave.clave ? 'Ocultar clave' : 'Mostrar clave'},on:{"click":function($event){_vm.mostrarCamposClave.clave = !_vm.mostrarCamposClave.clave}},slot:"append"})],1),(_vm.validarValores.clave == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica la clave ")]):_vm._e()],1),_c('div',{class:['col-12',
            {'campo': _vm.validarValores.confirmacionClave
              && _vm.validarValores.confirmacionClave.length},
            {'valido': _vm.validarValores.confirmacionClave == 'valido'},
            {'invalido': _vm.validarValores.confirmacionClave == 'invalido'} ]},[_c('mdb-input',{staticClass:"mt-2",attrs:{"type":_vm.mostrarCamposClave.confirmacionClave ? 'text' : 'password',"minlength":"8","label":"Confirmar clave","outline":""},model:{value:(_vm.confirmacionClave),callback:function ($$v) {_vm.confirmacionClave=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"confirmacionClave"}},[_c('mdb-btn',{attrs:{"slot":"append","group":"","flat":"","dark-waves":"","size":"md","icon":_vm.mostrarCamposClave.confirmacionClave ? 'eye-slash' : 'eye',"title":_vm.mostrarCamposClave.confirmacionClave
                ? 'Ocultar confirmación de la clave' : 'Mostrar confirmación de la clave'},on:{"click":function($event){_vm.mostrarCamposClave.confirmacionClave
                = !_vm.mostrarCamposClave.confirmacionClave}},slot:"append"})],1),(_vm.validarValores.confirmacionClave == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Confirma la clave ")]):_vm._e()],1)]):_c('div',[_c('p',{staticClass:"mb-n1 px-2 font-italic"},[_vm._v(" Se ha enviado un código a tu correo, revísalo para recuperar la cuenta ")]),_c('div',{class:['px-3',
            {'campo': _vm.validarValores.codigo && _vm.validarValores.codigo.length},
            {'valido': _vm.validarValores.codigo === 'valido'},
            {'invalido': _vm.validarValores.codigo === 'invalido'}]},[_c('mdb-input',{attrs:{"label":"Ingresa el código","outline":""},model:{value:(_vm.codigo),callback:function ($$v) {_vm.codigo=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"codigo"}})],1)]),_c('mdb-btn',{staticClass:"col-auto px-3",attrs:{"type":"submit","role":"button","color":"primario","icon":_vm.botonDeshabilitado ? 'circle-notch' : 'check',"icon-class":_vm.botonDeshabilitado ? 'fa-spin' : '',"disabled":_vm.botonDeshabilitado}},[_vm._v(" "+_vm._s(_vm.esRecuperarClave ? 'Guardar' : 'Confirmar')+" ")])],1)]),_c('AlertaMensaje',{attrs:{"alerta-mensaje":_vm.alertaMensaje},on:{"cerrar":function($event){_vm.alertaMensaje.contenido = ''}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }