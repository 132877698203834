<template>
  <section class="vista-formulario">
    <div
      class="tarjeta-form animated fadeInUp"
    >
      <h1 class="h5 user-select-none">Global Trackit System</h1>
      <h3 class="my-3 user-select-none">
        {{esRecuperarClave ? 'Nueva clave' : 'Recuperar cuenta'}}
      </h3>
      <form
        class="row justify-content-center"
        @submit.prevent="esRecuperarClave ? validarClave() : submitForm()"
      >
        <div v-if="esRecuperarClave" class="row mx-0">
          <div
            :class="['col-12',
              {'campo': validarValores.clave
                && validarValores.clave.length},
              {'valido': validarValores.clave == 'valido'},
              {'invalido': validarValores.clave == 'invalido'},
            ]"
          >
            <mdb-input
              v-model.trim="clave"
              :type="mostrarCamposClave.clave ? 'text' : 'password'"
              minlength="8"
              label="Clave"
              class="mt-2 mb-3"
              outline
            >
              <mdb-btn
                slot="append"
                group
                flat
                dark-waves
                size="md"
                :icon="mostrarCamposClave.clave ? 'eye-slash' : 'eye'"
                :title="mostrarCamposClave.clave ? 'Ocultar clave' : 'Mostrar clave'"
                @click="mostrarCamposClave.clave = !mostrarCamposClave.clave"
              />
            </mdb-input>
            <p
              v-if="validarValores.clave == 'invalido'"
              class="mensaje-invalido"
            >
              Indica la clave
            </p>
          </div>
          <div
            :class="['col-12',
              {'campo': validarValores.confirmacionClave
                && validarValores.confirmacionClave.length},
              {'valido': validarValores.confirmacionClave == 'valido'},
              {'invalido': validarValores.confirmacionClave == 'invalido'},
            ]"
          >
            <mdb-input
              v-model.trim="confirmacionClave"
              :type="mostrarCamposClave.confirmacionClave ? 'text' : 'password'"
              minlength="8"
              label="Confirmar clave"
              class="mt-2"
              outline
            >
              <mdb-btn
                slot="append"
                group
                flat
                dark-waves
                size="md"
                :icon="mostrarCamposClave.confirmacionClave ? 'eye-slash' : 'eye'"
                :title="mostrarCamposClave.confirmacionClave
                  ? 'Ocultar confirmación de la clave' : 'Mostrar confirmación de la clave'"
                @click="mostrarCamposClave.confirmacionClave
                  = !mostrarCamposClave.confirmacionClave"
              />
            </mdb-input>
            <p
              v-if="validarValores.confirmacionClave == 'invalido'"
              class="mensaje-invalido"
            >
              Confirma la clave
            </p>
          </div>
        </div>
        <div v-else>
          <p class="mb-n1 px-2 font-italic">
            Se ha enviado un código a tu correo, revísalo para recuperar la cuenta
          </p>
          <div
            :class="['px-3',
              {'campo': validarValores.codigo && validarValores.codigo.length},
              {'valido': validarValores.codigo === 'valido'},
              {'invalido': validarValores.codigo === 'invalido'}]"
          >
            <mdb-input
              v-model.trim="codigo"
              label="Ingresa el código"
              outline
            />
          </div>
        </div>
        <mdb-btn
          type="submit"
          role="button"
          color="primario"
          :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
          :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
          :disabled="botonDeshabilitado"
          class="col-auto px-3"
        >
          {{ esRecuperarClave ? 'Guardar' : 'Confirmar' }}
        </mdb-btn>
      </form>
    </div>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import {
  mdbBtn,
  mdbInput
} from 'mdbvue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import usuarioCambiarClaveGql from '@/graphql/usuarioCambiarClave.gql'
import verificarCodigoRecuperarClaveGql from '@/graphql/verificarCodigoRecuperarClave.gql'
import { onLogin } from '@/vue-apollo.js'
import { leerPersonaId } from '@/utils/datosToken.js'
export default {
  name: 'RecuperarClave',
  components: {
    mdbBtn,
    mdbInput,
    AlertaMensaje
  },
  data () {
    return {
      // Verificar si se encuentra en el paso de recuperar clave
      esRecuperarClave: false,
      // Otros
      alertaMensaje: { contenido: '' },
      botonDeshabilitado: false,
      codigo: '',
      validarValores: {},
      clave: '',
      confirmacionClave: '',
      mostrarCamposClave: {
        clave: false,
        confirmacionClave: false
      }
    }
  },
  methods: {
    submitForm () {
      this.validarValores = {
        codigo: this.codigo !== '' ? 'valido' : 'invalido'
      }
      if (this.codigo === '') {
        this.alertaMensaje = {
          contenido: 'Debes ingresar el código enviado al correo',
          tipo: 'error'
        }
        return
      }
      this.botonDeshabilitado = true
      this.$apollo
        .mutate({
          mutation: verificarCodigoRecuperarClaveGql,
          variables: {
            recuperacionId: this.codigo,
            usuario: this.$route.params.usuario
          }
        })
        .then(({ data: { verificarCodigoRecuperarClave } }) => {
          const { codigo, token } = verificarCodigoRecuperarClave
          switch (codigo) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido: 'Tu cuenta ha sido activada correctamente, ingresa tu nueva clave',
                tipo: 'correcto'
              }
              onLogin(this.$apolloProvider.clients.defaultClient, token)
              this.esRecuperarClave = true
              break
            case 'Fallido':
              this.validarValores.codigo = 'invalido'
              this.alertaMensaje = {
                contenido: 'El código que ingresaste no es válido',
                tipo: 'error'
              }
              break
            case 'UsuarioInactivo':
              this.validarValores.codigo = 'invalido'
              this.alertaMensaje = {
                contenido: 'El código de verificación que ingresaste ha expirado, hemos enviado uno nuevo a tu correo',
                tipo: 'advertencia'
              }
              break
            default:
              this.validarValores.codigo = 'invalido'
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error inesperado',
                tipo: 'error'
              }
              break
          }
          this.botonDeshabilitado = false
          this.codigo = ''
        })
        .catch((e) => {
          this.alertaMensaje = {
            contenido: `Error: ${e}`,
            tipo: 'error'
          }
          this.codigo = ''
          this.validarValores = {}
          this.botonDeshabilitado = false
        })
    },
    validarClave () {
      this.validarValores = {
        clave: this.clave.trim() ? 'valido' : 'invalido',
        confirmacionClave: this.confirmacionClave.trim() ? 'valido' : 'invalido'
      }
      // Verifica si existe algún dato invalido
      if (Object.values(this.validarValores).includes('invalido')) {
        this.alertaMensaje = {
          contenido: 'Todos los campos son requeridos',
          tipo: 'error'
        }
        this.botonDeshabilitado = false
        return
      }
      if (this.clave.length < 8) {
        return (this.alertaMensaje = {
          contenido: 'La clave debe contener al menos 8 caracteres',
          tipo: 'advertencia'
        })
      }
      if (this.clave !== this.confirmacionClave) {
        this.validarValores.confirmacionClave = 'invalido'
        return (this.alertaMensaje = {
          contenido: 'La confirmación de la clave no coincide. Por favor verifica tus datos',
          tipo: 'advertencia'
        })
      }
      this.usuarioCambiarClave()
    },
    usuarioCambiarClave () {
      this.botonDeshabilitado = true
      this.$apollo
        .mutate({
          mutation: usuarioCambiarClaveGql,
          variables: {
            personaId: leerPersonaId(),
            clave: this.clave
          }
        })
        .then(({ data: { usuarioCambiarClave } }) => {
          const msg = usuarioCambiarClave.codigo
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido: 'Se ha modificado la contraseña con éxito.',
                tipo: 'correcto'
              }
              this.limpiarCampos()
              this.botonDeshabilitado = false
              this.$router.push('/')
              break
            case 'Fallido':
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error modificando la contraseña.' +
                    ' Por favor intenta de nuevo',
                tipo: 'error'
              }
              this.botonDeshabilitado = false
              break
            default:
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                tipo: 'error'
              }
              this.botonDeshabilitado = false
              break
          }
        })
        .catch(() => {
          this.alertaMensaje = {
            contenido: 'Ha ocurrido un error inesperado. Por favor revisa tus datos e intenta nuevamente',
            tipo: 'error'
          }
          this.botonDeshabilitado = false
        })
    },
    limpiarCampos () {
      this.clave = ''
      this.confirmacionClave = ''
      this.validarValores = {}
      this.mostrarCamposClave = {
        clave: false,
        confirmacionClave: false
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.vista-formulario {
  .tarjeta-form {height: auto;}
  .campo {margin-bottom: 0;}
}

</style>
